import './process-detail.scss';

import React, { useEffect, useState } from 'react';
import {
  getAbsoluteURL,
  getDataWithAbsoluteURL,
  putDataWithAbsoluteURL
} from 'services/api-utils';

import Button from '@material-ui/core/Button';
import Moment from 'moment';
import Container from '@material-ui/core/Container';
import * as api from 'config/endpoints';
import  Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { useStore } from 'hooks';
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import FolderList from "./FolderList";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DocumentPreview from "./DocumentPreview";
import PdfViewerDialog from './PdfViewerDialog';



const REVIEW_STATUS = ['Backlog', 'Ready', 'Underway', 'Issue', 'Reviewed', 'Audited'];
const VALIDATION_TYPE = ['KEY PHRASE', 'PYTHON', 'AI ENGINE'];

const ProcessDetail = (props) => {
  const [lifecycleProcessData, setLifecycleProcessData] = useState();
  const [fileS3Data, setfileS3Data] = useState();
  const [fileNameS3Data, setNamefileS3Data] = useState();
  const [fileIdS3Data, setIDS3Data] = useState();
  const [fileIdS3LoadData, setfileS3LoadData] = useState();
  const [filePdf, setFilePdf] = useState();
  const [lifecycleProcessTitle, setLifecycleProcessTitle] = useState();
  const [validationLogsData, setValidationLogsData] = useState();
  const [popupContentValue, setPopupContentValue] = useState('');
  const [validationQuestion, setValidationQuestion] = useState('');
  const [validationStatement, setValidationStatement] = useState('');
  const [validationAnswer, setValidationAnswer] = useState('');
  const [popupNotesValue, setPopupNotesValue] = useState('');
  const [popupReviewStatusValue, setPopupReviewStatusValue] = useState('');
  const [validationType, setValidationType] = useState('KEY PHRASE');
  const [disableModal, setDisableModal] = useState(false);
  const [disableModalValidation, setDisableModalValidation] = useState(false);
  const [popupError, setPopupError] = useState(false);
  const [showMicroRegulatoryProcessLogs, setShowMicroRegulatoryProcessLogs] = useState(false);
  const [logCount, setLogCount] = useState(0);
  const [expandedTextArea, setExpandedTextArea] = useState(false);
  const [expandedNotesArea, setExpandedNotesArea] = useState(false);
  const [docType, setDocType] = useState(false);
  const store = useStore();
  const [popupValidationStatus, setPopupValidationStatus] = useState('');
  const [lastUpdatedDetails, setLastUpdatedDetails] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [isevidenceRule, setIsEvidenceRule] = useState(false);


  const handlePdfClick = () => {
    setOpen(!open);
    setIsLoading(false);
  };
  const handleCsvClick = () => {
    setIsLoading(!isLoading);
    setOpen(false);
  };

   const updateValidationType = (option) => {
    setValidationType('');
    setValidationType(option);
  };

  const getRuleData = async () => {
    const ruleContent = await getDataWithAbsoluteURL(props.lifecycleProcessUrl);
    setLifecycleProcessData(ruleContent);
  };

  useEffect(() => {
    setDocType(props.lifecycleStage);
    setIsEvidenceRule(props.ruleDocType  === 'Evidence');
    getRuleData();

  }, []);

  useEffect(() => {
    if (
      lifecycleProcessData &&
      lifecycleProcessData.company_micro_regulatory_process !== undefined
    ) {
      setPopupError(false);
      const documentData =
        lifecycleProcessData.company_micro_regulatory_process.micro_regulatory_process
          .regulation_document_sub_rule.regulation_document_rule;
      setLifecycleProcessTitle(documentData.rule_title);
      setPopupReviewStatusValue(lifecycleProcessData.review_status);
      setValidationType(lifecycleProcessData.validation_type);
      setValidationAnswer(lifecycleProcessData.validation_reason);
      setValidationQuestion(lifecycleProcessData.validation_question);
      setValidationStatement(lifecycleProcessData.validation_statement);
      setPopupValidationStatus(lifecycleProcessData.validation_status);
      setPopupContentValue(lifecycleProcessData.company_compliance_content);
      setPopupNotesValue(lifecycleProcessData.comment);
      setLastUpdatedDetails(lifecycleProcessData.last_updated);
      // setFiles(lifecycleProcessData.files)
      getFileProcedureData(lifecycleProcessData.company_micro_regulatory_process.id)
    } else {
      lifecycleProcessData !== undefined && setPopupError(true);
    }
  }, [lifecycleProcessData]);

  const saveRulePopupData = async () => {
    setDisableModal(true);
    const lifecycleProcessData = {
      company_compliance_content: popupContentValue,
      comment: popupNotesValue,
      review_status: popupReviewStatusValue,
      validation_type: validationType,
      validation_statement: validationStatement,
      validation_question: validationQuestion,
      save: true,
    };
    await putDataWithAbsoluteURL(props.lifecycleProcessUrl, lifecycleProcessData);
    props.triggerReloadContent();
    props.closePopup();
  };

  const validationRulePopupData = async () => {
    setDisableModalValidation(true);
    if (props.ruleDocType == 'Evidence') {
      let absoluteURL = getAbsoluteURL(`${api.EVIDENCE_PROCESS_VALIDATION}${props.lifecycleProcessId}/`);
      const lifecycleProcessData = {
        company_compliance_content: popupContentValue,
        comment: popupNotesValue,
        review_status: popupReviewStatusValue,
        validation_type: validationType,
        save: false,
        validation_statement: validationStatement,
        validation_question: validationQuestion
    };
    await putDataWithAbsoluteURL(absoluteURL, lifecycleProcessData);
  } else {
      const lifecycleProcessData = {
      company_compliance_content: popupContentValue,
      comment: popupNotesValue,
      review_status: popupReviewStatusValue,
      validation_type: validationType,
      save: true,
    };
    await putDataWithAbsoluteURL(props.lifecycleProcessUrl, lifecycleProcessData);
  }
   setDisableModalValidation(false);
   getRuleData();
  };

  const closePopupData = async () => {
    props.triggerReloadContent();
    props.closePopup();
  };

  const linkFolder = async (id, file) => {
    const lifecycleProcessData = {
      company_micro_regulatory_process_id:id,
      folder: file['folder'],
      path: removeFileNameFromPath(file['path'])
    };
    const msg = store.api.filesS3Link(lifecycleProcessData);
    props.triggerReloadContent();
    props.closePopup();
  };

  const getListFolderRoot = async (id, i) => {
    setIsLoading(false);
    setFilePdf(undefined);
    const data = await store.api.getFilesS3ListRoot(id);
    setfileS3Data(data.data);
    if (i === undefined && data.data.length > 0) {
      setNamefileS3Data(data.data[0].file_name);

    }else{
      setNamefileS3Data(data.data[0].file_name);
    }
  };

  const getFileProcedureData = async (id, i) => {
    setIsLoading(false);
    setFilePdf(undefined);
    let folder = id;
    if (typeof id == 'string') {
      folder = removeFileNameFromPath(id);
    }
    const data = await store.api.getFilesS3List(folder);
    setfileS3Data(data.data);
    if (i === undefined && data.data.length > 0) {
      setNamefileS3Data(data.data[0].file_name);

    }else{
      setNamefileS3Data(data.data[0].file_name);
    }
  };


  const getFileS3ViewData = async (path) => {
    let pathFilter = path.split('/').join('>');
    const data = await store.api.getS3File(pathFilter);
    let extension = path.split('.');
    if (extension[extension.length -1] === 'csv'){
         setfileS3LoadData(data.data);
         setIsLoading(true);
    }else{
        let  final = "data:application/pdf;base64, "
        let result = final.concat(data.data);
        setFilePdf(result);
        setIsLoading(false);
    }
  };

  const updateRulePopupContentValue = (e) => {
    let content = e.target.value;
    setPopupContentValue(content);
  };

  const updateRulePopupNotesValue = (e) => {
    let content = e.target.value;
    setPopupNotesValue(content);
  };
  const updateQuestionValue = (e) => {
    let content = e.target.value;
    setValidationQuestion(content);
  };
    const updatevalidationStatement= (e) => {
    let content = e.target.value;
    setValidationStatement(content);
  };

  const updateRuleStatusValue = (ruleStatus) => {
    setPopupReviewStatusValue('');
    setPopupReviewStatusValue(ruleStatus);
  };

  const navigateToBusiness = (businessId) => {
    const path = '/business?business=' + businessId;
    history.push(path);
  };
  function removeFileNameFromPath(path) {
    // Regex to match paths ending with '.pdf' or '.csv' file names considering '>' as a delimiter
    const regex = /(.*)>[^>]+\.pdf$|(.*)>[^>]+\.csv$/i;

    // Execute the regex on the provided path
    const match = path.match(regex);

    // If a match is found and the file name is at the end, return the path without the file name
    if (match) {
        return match[1] || match[2];
    }

    // If no file name is at the end, return the original path
        return path;
  };
  function doupload() {
    let data = document.getElementById("raised-button-file").files[0];
    let entry = document.getElementById("raised-button-file").files[0];
    const formData = new FormData();
    debugger;
    formData.append('folder', removeFileNameFromPath(fileS3Data[0].path));
    formData.append('name', entry.name);
    formData.append('file', data)
    formData.append('company_micro_regulatory_process_id', lifecycleProcessData.company_micro_regulatory_process.id)
    const msg = store.api.sendFilesS3Sent(formData);
    alert('your file has been uploaded');
  };


  return (
    <div className="rules-popup">
      {!lifecycleProcessData ||
      lifecycleProcessData.company_micro_regulatory_process === undefined ||
      disableModal || disableModalValidation ? (
        <div className="rules-popup-content">
          <div className="close-popup" onClick={() => props.closePopup()}></div>
          <div className="loading-modal-content">
            {disableModal ? 'Saving' : popupError ? 'Error finding rule' : 'Loading'}
          </div>
          <div className="loading-modal-content">
            {disableModalValidation ? 'Validating' : popupError ? 'Error finding rule' : 'Loading'}
          </div>
        </div>
      ) : (
        <div className="rules-popup-content">
          <div className="close-popup" onClick={() => props.closePopup()}></div>

          <div className="popup-modal-header">
            <div className="header-title">
              {
                lifecycleProcessData.company_micro_regulatory_process.micro_regulatory_process
                  .regulation_document_sub_rule.regulation_document_rule.rule_number
              }
              | {lifecycleProcessTitle}
            </div>
            <div
              className={`rule-status-menu
                ${popupReviewStatusValue === 'Ready' ? 'passed' : ''}
                ${popupReviewStatusValue === 'Audited' ? 'passed' : ''}
              `}
            >
              {popupReviewStatusValue}
              <div className="menu-options">
                {REVIEW_STATUS.map((ruleStatus, i) => {
                  return (
                    <div
                      key={`option-${i}`}
                      className="option"
                      onClick={() => updateRuleStatusValue(ruleStatus)}
                    >
                      {ruleStatus}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="validation-status-details">
              Validated - {lifecycleProcessData.validation_frequency}
            </div>
            <div
              className={`validation-status
                ${popupValidationStatus === 'Failed' ? 'failed' : ''}
                ${popupValidationStatus === 'Passed' ? 'passed' : ''}
                ${popupValidationStatus === 'Underway' ? 'underway' : ''}
                ${popupValidationStatus === 'Scheduled' ? 'scheduled' : ''}
                ${popupValidationStatus === 'Investigate' ? 'investigate' : ''}
                `}
            >
              {popupValidationStatus}
            </div>

            {showMicroRegulatoryProcessLogs && (
              <div className="validation-logs-dropdown">
                <span>Validation logs [{logCount}]</span>
                <ul>
                  {validationLogsData !== undefined &&
                    validationLogsData.results !== undefined &&
                    validationLogsData.results.map((logItem, i) => {
                      const logStatus = logItem.rule_validation_status;
                      return (
                        <li key={`log-${i}`}>
                          <span>
                            <span className="title">
                              <span
                                className={`log-status
                              ${logStatus === 'Failed' ? 'failed' : 'passed'}
                            `}
                              >
                                {logStatus}
                              </span>
                              <span className="log-status-date">
                                {Moment(logItem.created_on).format('MMM Do YYYY, h:mm a')}
                              </span>
                            </span>
                            <br />
                            <span className="log-status-content">
                              {logItem.rule_validation_info}
                            </span>
                          </span>
                        </li>
                      );
                    })}
                </ul>
                {validationLogsData !== null ||
                  (validationLogsData === 'Rule Content Not Found' && validationLogsData)}
              </div>
            )}
          </div>
          <div className="popup-modal-content">
            <div className="title">Aims of the {props.documentTitle} document</div>
            <p>{lifecycleProcessData.aims}</p>
            <div className="title">Rule content</div>
            <p>
              {
                lifecycleProcessData.company_micro_regulatory_process.micro_regulatory_process
                  .regulation_document_sub_rule.content
              }
            </p>
            <div className="title">Reasons for applicability</div>
            <p>{lifecycleProcessData.company_micro_regulatory_process.applicability_reason}</p>
            <div className="title">Details as to how the firm Complies</div>
            <p>{lifecycleProcessData.dynamic_compliance_content}</p>

            <div className="title">Keyphrases</div>
            <p>
              {lifecycleProcessData.company_micro_regulatory_process
                .company_micro_regulatory_process_key_phrases !== undefined &&
                lifecycleProcessData.company_micro_regulatory_process.company_micro_regulatory_process_key_phrases.map(
                  (keyPhrase, i) => {
                    if (i == 0) return <li key={`key-phrase-${i}`}>{keyPhrase}</li>;
                  },
                )}
            </p>

            <div className="title">
              {props.ruleDocType  != 'Evidence' ? 'Firm Specific Compliance' +
                  ' Information' : 'Key Phase Validation'}
              <div className="last-updated">
                Last updated: {Moment(lastUpdatedDetails).format('DD MM YYYY, h:mm a')}
              </div>
              <div
                className={`expand-text-area ${expandedTextArea ? 'expanded-state' : ''}`}
                onClick={() => setExpandedTextArea(!expandedTextArea)}
              ></div>
            </div>
            <TextField
              className="input-text"
              type="text"
              variant="outlined"
              value={popupContentValue ? popupContentValue : ' '}
              onChange={(e) => updateRulePopupContentValue(e)}
              multiline
              style={{ fontSize: 12 }}
              minRows={expandedTextArea ? 35 : 12}
            />
            <div className="title">
            {props.ruleDocType  != 'Evidence' ? 'Additional Information': 'Python Code Validation'}
            <div
              className={`expand-text-area ${expandedNotesArea ? 'expanded-state' : ''}`}
              onClick={() => setExpandedNotesArea(!expandedNotesArea)}
            ></div>
            </div>
            <TextField
              className="input-text"
              type="text"
              variant="outlined"
              multiline
              minRows={expandedNotesArea ? 35 : 12}
              value={popupNotesValue ? popupNotesValue : ' '}
              onChange={(e) => updateRulePopupNotesValue(e)}
            />

            {isevidenceRule && (
              <>
                <div className="title">
                  Validation Question
                  <div
                    className={`expand-text-area ${expandedNotesArea ? 'expanded-state' : ''}`}
                    onClick={() => setExpandedNotesArea(!expandedNotesArea)}
                  ></div>
                </div>
                <TextField
                  className="input-text"
                  type="text"
                  variant="outlined"
                  multiline
                  minRows={expandedNotesArea ? 35 : 3}
                  value={validationQuestion ? validationQuestion : ' '}
                  onChange={(e) => updateQuestionValue(e)}
                />
              </>
           )}
           {isevidenceRule && (
              <>
                <div className="title">
                  Validation Statement
                  <div
                    className={`expand-text-area ${expandedNotesArea ? 'expanded-state' : ''}`}
                    onClick={() => setExpandedNotesArea(!expandedNotesArea)}
                  ></div>
                </div>
                <TextField
                  className="input-text"
                  type="text"
                  variant="outlined"
                  multiline
                  minRows={expandedNotesArea ? 35 : 3}
                  value={validationStatement ? validationStatement : ' '}
                  onChange={(e) => updatevalidationStatement(e)}
                />
              </>
           )}
          {isevidenceRule && (
              <>
                <div className="title">
                  Validation Answer
                  <div
                    className={`expand-text-area ${expandedNotesArea ? 'expanded-state' : ''}`}
                    onClick={() => setExpandedNotesArea(!expandedNotesArea)}
                  ></div>
                </div>
                <TextField
                  className="input-text"
                  type="text"
                  variant="outlined"
                  multiline
                  minRows={expandedNotesArea ? 35 : 3}
                  value={validationAnswer ? validationAnswer : ' '}
                />
              </>
           )}
            {isevidenceRule && (
                <div>
            <div className="title">
              Evidence Document Folder
              <div
                className={`expand-text-area ${expandedNotesArea ? 'expanded-state' : ''}`}
                onClick={()=> getListFolderRoot(lifecycleProcessData.company_micro_regulatory_process.id)}></div>
            </div>
              <FolderList filesList={fileS3Data} isFolder={true} onClick={getFileProcedureData}/>

            {fileS3Data !== undefined &&
              <div className="title">
              Evidence Document Library
              <FolderList filesList={fileNameS3Data} isFolder={false}  onClick={getFileS3ViewData}/>
              </div>}
            {isLoading && fileIdS3LoadData !== undefined &&  <DocumentPreview table_json={fileIdS3LoadData} closePopup={() => props.closePopup()} handleCsvClick={() => handleCsvClick()}/>}
            {filePdf !== undefined &&
              <div className="title">
              Evidence Document Preview
              {filePdf !== undefined  && open === false &&
               <PdfViewerDialog
                 pdfUrl={filePdf}
                 closePopup={() => props.closePopup()}
                 closeDialog={() => handlePdfClick()}/>
              }
              </div>
            }
             </div>)}
         <div>
           <Container
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '10vh',
              }}
            >
           <Box sx={{ flexGrow: 1 }}>
           <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                  style={{
                    gap: '2px' // Space between buttons
                  }}>
                 <Button color="primary"
                         variant="contained"
                         component="span"
                          hidden={!isevidenceRule}
                         onClick={() => closePopupData()}>Exit</Button>
                 {isevidenceRule && (
                   <>
                        <input
                        accept="*/*"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                        onChange={()=>doupload(lifecycleProcessData.company_micro_regulatory_process.id)}
                      />
                      <label htmlFor="raised-button-file">
                        <Button  color="primary"
                                 variant="contained"
                                 component="span">
                          Upload File
                        </Button>
                      </label>

                    <Button
                      variant="contained"
                      component="span"
                      visibility={"hidden"}
                      color="primary"
                      onClick={() => linkFolder(lifecycleProcessData.company_micro_regulatory_process.id, fileS3Data[0])}>
                      {disableModal ? 'LINKING' : 'LINK'}
                      </Button>

                     <Button
                      variant="contained"
                      component="span"
                      color="primary"
                      hidden={!isevidenceRule}
                      onClick={() => validationRulePopupData(lifecycleProcessData.url)}>
                      {disableModal ? 'VALIDATING' : 'VALIDATION'}
                      </Button>
                   </>)}
                    <Button
                    variant="contained"
                    component="span"
                    color="primary"
                    onClick={() => saveRulePopupData(lifecycleProcessData.url)}>
                    {disableModal ? 'SAVING' : 'SAVE'}
                    </Button>
               </ButtonGroup>
               </Box>
               {isevidenceRule && (
                   <Box>
                   <div
                    className={`rule-status-menu
                      ${popupReviewStatusValue === 'Ready' ? 'passed' : ''}
                      ${popupReviewStatusValue === 'Audited' ? 'passed' : ''}
                    `}
                  >
                    {validationType}
                    <div className="menu-options">
                      {VALIDATION_TYPE.map((validationType, i) => {
                          return (
                            <div
                              key={`option-${i}`}
                              className="option"
                              onClick={() => updateValidationType(validationType)}
                            >
                              {validationType}
                            </div>
                          );
                        })}
                    </div>
                  </div>
               </Box>)}
           </Container>
            </div>
          </div>
        </div>
      )}
      <div className="rules-popup-background"></div>
    </div>
  );
};

ProcessDetail.defaultProps = {};

export default ProcessDetail;
