import React, { useState, useEffect } from 'react';
import {
    Line,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip
} from 'recharts';


export default function ReportGraph (props) {
  const seriesData = Object.values(props.data)[props.index]?.results || [];
  const [chartWidth, setChartWidth] = useState(Math.max(500, Math.min(1000, window.innerWidth - 950 * (1500 / window.innerWidth))));

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(Math.max(500, Math.min(1000, window.innerWidth - 950 * (1500 / window.innerWidth))));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formatTimestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Function to fill the missing days with last available values
  // Function to fill gaps between startDate and endDate in proper chronological order
  const fillGapBetweenDates = (data, startDate, endDate) => {
    if (data.length === 0) return [];

    let result = [...data];
    const firstRecordDate = new Date(data[0].created_on);
    const lastRecordDate = new Date(data[data.length - 1].created_on);
    let currentDate = new Date(startDate);

    const firstRecord = data[0];
    const lastRecord = data[data.length - 1];

    const backwardFilledRecords = [];

    while (currentDate < firstRecordDate) {
      const newRecord = {
        ...firstRecord,
        created_on: currentDate.toISOString().split('T')[0] + "T00:00:00",
      };
      backwardFilledRecords.push(newRecord);
      currentDate.setDate(currentDate.getDate() + 1);
    }


    result = [...backwardFilledRecords, ...result];


    currentDate = new Date(lastRecordDate);
    while (currentDate < endDate) {
      currentDate.setDate(currentDate.getDate() + 1);
      if (currentDate > endDate) break;

      const newRecord = {
        ...lastRecord,
        created_on: currentDate.toISOString().split('T')[0] + "T00:00:00",
      };
      result.push(newRecord);
    }

    return result;
  };

  const selectedStartDate = props.selectedDate || new Date();
  const selectedEndDate = props.selectedEndDate || new Date();

  const filledData = fillGapBetweenDates(seriesData, selectedStartDate, selectedEndDate);

  const result = filledData.map((item) => ({
    name: formatTimestampToDate(item.created_on),
    Passed: (item.total_rules_validation_passed / item.applicable_micro_regulatory_processes).toFixed(2),
    amt: 1
  }));

  const tickData = [
    result[0]?.name,
    result[Math.floor(result.length / 2)]?.name,
    result[result.length - 1]?.name
  ];

  const toPercentage = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

  return (
    <AreaChart
      width={chartWidth}
      height={120}
      data={result}
      margin={{ top: 5, right: 50, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" ticks={tickData} />
      <YAxis domain={[0, 1]} tickFormatter={(value) => toPercentage(value, 0)} />
      <Tooltip formatter={(value) => toPercentage(value, 2)} />
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#f70000" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#f70000" stopOpacity={0} />
        </linearGradient>
      </defs>
      <Area
        type="monotone"
        dataKey="Passed"
        stroke="#82ca9d"
        activeDot={{ r: 8 }}
        fillOpacity={1}
        fill="url(#colorUv)"
      />
      <Line type="monotone" dataKey="Passed" stroke="#8b8989" />
    </AreaChart>
  );
};